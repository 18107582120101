export const pageBanner = {
  group: {
    title: "Groups",
    description:
      "Members are free to join Expert groups. You are assigned into Committee groups and Peer Networks by role(s) or by expertise. ",
  },
  events: {
    title: "Events",
    description: "Attend, host and create online event rooms",
  },
  equityResearch: {
    title: "Equity Research",
    description: "FTSE 350 equity research at a glance",
  },
  regulatoryNews: {
    title: "Regulatory News",
    description: "FTSE 350 regulatory news at a glance",
  },
  corporateNews: {
    title: "Corporate News",
    description: "FTSE 350 corporate news at a glance",
  },
};
